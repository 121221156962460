import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Fade } from '../../utils'

import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import { heading1, heading2, heading3, links } from '../../styles/type'
import { brown3, brown1, brown4, brown2 } from '../../styles/colors'

const Intro = (props) => {
    // console.log(props)
    return (
        <Wrapper>
            <Fade delay={800}>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: props.heading,
                        }}
                    />
                    <Grid>
                        <Subheading
                            dangerouslySetInnerHTML={{
                                __html: props.subheading,
                            }}
                        />
                        <Description>
                            <Info
                                dangerouslySetInnerHTML={{
                                    __html: props.description,
                                }}
                            />
                            {props.link && (
                                <ExternalLink href={props.link.url} target={props.link.target}>
                                    {props.link.title}
                                </ExternalLink>
                            )}
                        </Description>
                    </Grid>
                </Container>
            </Fade>
        </Wrapper>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const ExternalLink = styled.a``

// Layout

const Container = styled.div`
    ${container}
    ${padding}
`
const Grid = styled.div``

// Intro

const Wrapper = styled.div`
    /* min-height: 880px; */
    padding-top: 136px;
    padding-bottom: 64px;
    box-sizing: border-box;
    background-color: ${brown3};

    ${media.tabletLandscape`
        min-height: initial;
    `}

    ${media.phone`
        padding-top: 100px;
        padding-bottom: 50px;
        background-color: white;
    `}

    ${Heading} {
        ${heading1};
    }

    ${Grid} {
        display: flex;
        margin: 64px -20px 0;

        ${media.tabletLandscape`
            margin: 120px -20px 0;
        `}

        ${media.phone`
            display: block;
            margin: 0;
        `}
    }

    ${Subheading}, ${Description} {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;

        ${media.phone`
            width: 100%;
            padding: 0;
        `}
    }

    ${Subheading} {
        ${heading3};

        ${media.phone`
            margin-top: 96px;
        `}
    }

    ${Info} {
        ${media.phone`
            margin-top: 30px;
        `}

        * {
            ${heading2};
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    ${ExternalLink} {
        margin-top: 42px;
        ${links};
        transition: border-color 0.15s ease, color 0.15s ease;

        &:hover {
            color: ${brown1};
            border-color: ${brown1};
        }

        ${media.phone`
            display: none;
        `}
    }
`

export default Intro
