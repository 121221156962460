import React from 'react'
import { navigate, Link, useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { media } from '../../styles/utils'
import ProgressiveImage from 'react-progressive-image'

import { container, padding, hoverState, bgImage } from '../../styles/global'
import { navigation } from '../../styles/type'
import { coolGrey, white, blue2, brown1, brown2, brown5 } from '../../styles/colors'

import logoDark from '../../assets/images/logos/desktop/logo-dark.svg'

const Footer = props => {
    const graphData = useStaticQuery(query)
    const data = JSON.parse(graphData.wordpressGlobal.acf_json)
    // console.log(data)

    const renderAddress = () => {
        return (
            <Address>
                <Heading>Visit Us</Heading>
                <Info
                    dangerouslySetInnerHTML={{
                        __html: data.address,
                    }}
                />
            </Address>
        )
    }

    const renderContact = () => {
        const socialItems = data.social_links.map((item, i) => {
            return (
                <ContactItem key={i}>
                    <Label>S</Label>
                    <ExternalLink href={item.url} target="_blank">
                        {item.title}
                    </ExternalLink>
                </ContactItem>
            )
        })
        return (
            <Contact>
                <Heading>Connect</Heading>
                <ContactItems>
                    <ContactItem>
                        <Label>T</Label>
                        <ExternalLink href={`tel: ${data.phone}`}>
                            {data.phone}
                        </ExternalLink>
                    </ContactItem>
                    <ContactItem>
                        <Label>E</Label>
                        <ExternalLink href={`mailto: ${data.email}`}>
                            {data.email}
                        </ExternalLink>
                    </ContactItem>
                    {socialItems}
                </ContactItems>
            </Contact>
        )
    }

    return (
        <Wrapper>
            <Container>
                <Left>
                    <InternalLink to="/">
                        KardosScanlan
                    </InternalLink>
                    {renderAddress()}
                    {renderContact()}
                </Left>

                <Right>
                    <LogoLink to={'/'}>
                        <Logo src={logoDark} />
                    </LogoLink>
                    <InternalLink to={'/privacy-policy'}>
                        Privacy Policy
                    </InternalLink>
                </Right>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const ExternalLink = styled.a``
const InternalLink = styled(Link)``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 100px;
    background-color: ${white};

    ${media.phone`
        padding-bottom: 40px;
    `}
`

const Container = styled.div`
    ${container}
    ${padding}
    display: flex;

    ${media.phone`
        display: block;
    `}
`

// Left

const Left = styled.div`
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin: 0 -10px;

    ${media.phone`
        width: 100%;
        flex-wrap: wrap;
        margin: 0;
    `}

    > * {
        width: 33.333%;
        padding: 0 10px;
        box-sizing: border-box;

        ${media.phone`
            padding: 0;

            &:first-child {
                width: 100%;
                flex: 100%;
            }
            &:not(first-child) {
                width: auto;
                flex: 1;
            }
        `}
    }

    ${Heading}, > ${InternalLink} {
        margin-bottom: 30px;
        font-family: Basis Grotesque;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.667;
    }

    ${Heading} {
        color: ${blue2};

        ${media.phone`
            margin-bottom: 2px;
        `}
    }

    > ${InternalLink} {
        color: ${brown1};
        ${hoverState};
    }
`

// Address

const Address = styled.div`
    font-family: Basis Grotesque;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
    color: ${brown1};
`

// Contact

const ContactItems = styled.div``
const ContactItem = styled.div``
const Label = styled.div``
const Contact = styled.div`
    ${ContactItem} {
        display: flex;
    }

    ${Label} {
        margin-right: 30px;
        font-family: Basis Grotesque;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.25;
        color: ${brown1};

        ${media.phone`
            margin-right: 8px;
        `}
    }

    ${ExternalLink} {
        font-family: Basis Grotesque;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.25;
        color: ${brown5};
        transition: color 0.15s ease;

        &:hover {
            color: ${brown1};
        }
    }
`

// Right

const LogoLink = styled(Link)`
    ${hoverState};
`

const Right = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    ${media.phone`
        width: 100%;
        align-items: flex-start;
    `}

    ${InternalLink} {
        margin-top: 30px;
        font-family: Basis Grotesque;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.25;
        color: ${brown5};
        transition: color 0.15s ease;

        &:hover {
            color: ${brown1};
        }

        ${media.phone`
            order: -1;
            margin-top: 64px;
        `}
    }

    ${LogoLink} {
        ${media.phone`
            margin-top: 30px;
        `}
    }
`

// Logo

const Logo = styled.img``

const query = graphql`
    query FooterQuery {
        wordpressGlobal {
            acf_json
        }
    }
`

export default Footer
