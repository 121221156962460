import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-use'
import Transition from '../../transition'
import { ParallaxProvider } from 'react-scroll-parallax';

import { MetaTags, Header, Footer } from '../'
import { media } from '../../styles/utils'
import { headerHeight } from '../../styles/global'

const Layout = ({ children, meta, desktopHeaderTheme, mobileHeaderTheme }) => {
    const location = useLocation()

    return (
        <>
            <MetaTags {...meta} />

            <Header
                location={location}
                mobileHeaderTheme={mobileHeaderTheme}
                desktopHeaderTheme={desktopHeaderTheme}
            />

            <Wrapper location={location}>
                <ParallaxProvider>
                    <Transition>
                        {children}
                    </Transition>
                </ParallaxProvider>
            </Wrapper>

            <Footer />
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`

export default Layout
