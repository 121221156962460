import React from 'react'
import { navigate, Link } from 'gatsby-link'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import ReactPlayer from 'react-player'
import { useSetState } from 'react-use';

import { media, isClient } from '../../../styles/utils'
import { container, padding, hoverState, bgImage, bgIcon } from '../../../styles/global'
import { body } from '../../../styles/type'
import { blue2 } from '../../../styles/colors'

const BlockMedia = (props) => {
    const { media, layout, image, video, src, block_type } = props;	


    
	const [playerState, setPlayerState] = useSetState({
		muted: true,
		playing: false,
	});
		
	const renderCaption = () => {
		const { caption, use_caption } = props;
		if (!use_caption) return

		return (
			<Caption
				dangerouslySetInnerHTML={{__html: caption}}  
			/>
		)
	}
	
	const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe.match(/src="(.+?)"/)[1]
		}
	}
	
	const renderMedia = () => {
		return (
			<>
				{(layout == 'single_image' || layout == 'image') && image && (
					<Image
						key={image.id}
						src={image.sizes && image.sizes.medium2}
					>
						{(src, loading) => {
							return (
								<>
									<BGImage
										landscape={image.height < image.width}
										image={src}
										style={{opacity: loading ? 0 : 1}}
									/>
								</>
							)
						}}
					</Image>
				)}

				{layout == 'video' && video && (
					<VideoWrapper>
						<Video
							url={getVideoURL(video)}
							className={'media'}
							volume={1}
							muted={playerState.muted}
							playing={playerState.playing}
						/>
					</VideoWrapper>
				)}
		
				{renderCaption()}
			</>
		)
	}
	
	return (	
		<Container
			type={block_type}
		>
			{renderMedia()}
		</Container>
	)
}


// Image

const Fade = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`


const BGImage = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
	height: 100%;
	width: 100%;
	z-index: 2;
	position: relative;

	${props => {
		if (!props.landscape) return css`
			${bgIcon}
		`
	}}
`

const VideoWrapper = styled.div`
	position: relative;
`

const Video = styled(ReactPlayer)`
	width: 100% !important;
`

// Layout

const Container = styled.div`
	width: 100%;
	position: relative;
	
	${BGImage} {
		overflow: hidden;
		padding-bottom: 73%;
		max-height: 770px;
		/* background: black; */

		${media.phone`
			height: 350px !important;
		`}
	}
`

// Caption

const Caption = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	${body}
    a {
        color: ${blue2};
    }
`

// Controls

const Sound = styled.div``
const PlayPause = styled.div``

const Controls = styled.div`
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 2;
	display: flex;
	user-select: none;

	${Sound},
	${PlayPause} {
		color: #9E9E9E;
		cursor: pointer;
	}

	${PlayPause} {
		margin-right: 126px;
	}
`

BlockMedia.wrapper = css`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export default BlockMedia
