import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'

const BlockHeading = (props) => {
	const { content } = props;
        
	return (
		<Heading>{content}</Heading>
	)
}


const Heading = styled.h2`
	font-size: 24px;
    font-weight: 700;
	line-height: 1.25;
`

BlockHeading.wrapper = css`

`

export default BlockHeading
