import React from 'react'
import {default as FadeAnimation} from 'react-reveal/Fade';

export const Fade = ({children, ...restProps}) => {
    return (
        <FadeAnimation
            bottom
            distance={'40px'}
            {...restProps}
        >
            {children}
        </FadeAnimation>
    )
}