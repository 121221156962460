import { 
    BlockText,
    BlockHeading,
    BlockMedia
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'image': BlockMedia,
        'heading': BlockHeading,
    }
    
    return blocks[layout]
}


