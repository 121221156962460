import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useBreakpoint } from '../../styles/utils'
import { useWindowScroll, useToggle } from 'react-use'
import { useLocation } from 'react-use'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import {
    container,
    padding,
    hoverState,
    headerHeight,
} from '../../styles/global'
import { navigation } from '../../styles/type'
import {
    coolGrey,
    white,
    brown4,
    brown3,
    brown1,
    brown2,
    brown5,
} from '../../styles/colors'

import logoLightDesktop from '../../assets/images/logos/desktop/logo-light.svg'
import logoLightMobile from '../../assets/images/logos/mobile/logo-light.svg'
import logoDarkMobile from '../../assets/images/logos/mobile/logo-dark.svg'
import burgerDark from '../../assets/images/icons/burger-dark.svg'
import burgerLight from '../../assets/images/icons/burger-light.svg'
import close from '../../assets/images/icons/close.svg'

let lastScrollTop = 0

const Header = props => {
    const [scrolledPastHeader, setScrolledPastHeader] = useState(false)
    const [fixedNav, setFixedNav] = useState(false)
    const [mobileMenuActive, toggleMobileMenu] = useToggle(false)
    const [locked, toggleLocked] = useToggle(false)
    const { x, y } = useWindowScroll()
    const location = useLocation()

    const graphData = useStaticQuery(query)
    const data = JSON.parse(graphData.wordpressGlobal.acf_json)

    // window scroll listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // mobile nav lock body scroll
    const mobileMenu = useRef(null)
    useEffect(() => {
        if (locked) {
            disableBodyScroll(mobileMenu.current)
        } else {
            clearAllBodyScrollLocks()
        }
    })

    const handleScroll = () => {
        var st = window.pageYOffset || document.documentElement.scrollTop
        const scrollY = window.scrollY

        // set scrolled past header
        if (scrollY > headerHeight) {
            setScrolledPastHeader(true)
        } else {
            setScrolledPastHeader(false)
        }

        // set fixednav
        if (st > lastScrollTop) {
            // downscroll
            if (scrollY > headerHeight) {
                setFixedNav(false)
            }
        } else {
            // upscroll
            if (scrollY > headerHeight) {
                setFixedNav(true)
            } else if (scrollY === 0) {
                setFixedNav(false)
            }
        }

        lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
    }

    const renderMenuItems = () => {
        const menu = [
            {
                title: 'Home',
                link: '/',
            },
            {
                title: 'Our Story',
                link: '/our-story',
            },
            {
                title: 'Expertise',
                link: '/expertise',
            },
            {
                title: 'Recent Transactions',
                link: '/recent-transactions',
            },
            {
                title: 'Team',
                link: '/team',
            },
            {
                title: 'Contact',
                link: '/contact',
            },
        ]

        return menu.map((item, i) => {
            return (
                <MenuItem key={i} to={item.link} activeClassName={'active'}>
                    {item.title}
                </MenuItem>
            )
        })
    }

    const renderMenu = () => {
        return <Menu>{renderMenuItems()}</Menu>
    }

    const renderMobileMenu = () => {
        const items = data.social_links.map((item, i) => {
            return (
                <ExternalLink key={i} href={item.url}>
                    {item.title}
                </ExternalLink>
            )
        })

        return (
            <MobileMenu ref={mobileMenu} {...toggleOpacity}>
                <HomeLink to="/">
                    <Logo src={logoLightDesktop} />
                </HomeLink>

                {renderMenuItems()}

                <SocialNav>
                    <Heading>Follow</Heading>
                    {items}
                </SocialNav>
            </MobileMenu>
        )
    }

    return (
        <Wrapper
            animate={fixedNav || y === 0 ? 'visible' : 'hidden'}
            {...togglePosition}
        >
            <Container>
                {/* Desktop Menu */}
                {!useBreakpoint('tablet') && renderMenu()}

                {/* Desktop Elements */}
                {!useBreakpoint('tablet') && (
                    <Fragment>
                        {/* Default header theme (light) */}
                        <AnimatePresence>
                            {props.desktopHeaderTheme !== 'dark' && fixedNav && (
                                <Fragment>
                                    {/* BG Colour */}
                                    <BGColour
                                        {...toggleOpacity}
                                        style={{
                                            backgroundColor: `${coolGrey}`,
                                        }}
                                    />

                                    {/* Logo */}
                                    <motion.div
                                        {...toggleOpacity}
                                        style={{ zIndex: 40 }}
                                    >
                                        <HomeLink to="/">
                                            <Logo src={logoLightDesktop} />
                                        </HomeLink>
                                    </motion.div>
                                </Fragment>
                            )}
                        </AnimatePresence>

                        {/* Dark header theme  */}
                        {props.desktopHeaderTheme === 'dark' && (
                            <Fragment>
                                <BGColour
                                    style={{ backgroundColor: `${coolGrey}` }}
                                />

                                <HomeLink to="/">
                                    <Logo src={logoLightDesktop} />
                                </HomeLink>
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {/* Mobile Menu */}
                <AnimatePresence>
                    {useBreakpoint('tablet') &&
                        mobileMenuActive &&
                        renderMobileMenu()}
                </AnimatePresence>

                {/* Mobile Elements */}
                {useBreakpoint('tablet') && (
                    <Fragment>
                        {/* Mobile Toggle */}
                        <MobileNavToggle
                            onClick={() => {
                                toggleMobileMenu()
                                toggleLocked()
                            }}
                        >
                            {!mobileMenuActive && (
                                <Hamburger
                                    src={
                                        fixedNav ||
                                        props.mobileHeaderTheme === 'light' ||
                                        scrolledPastHeader
                                            ? burgerLight
                                            : burgerDark
                                    }
                                />
                            )}
                            {mobileMenuActive && <Close src={close} />}
                        </MobileNavToggle>

                        {/* Mobile Logo - Home */}
                        {location.pathname === '/' && scrolledPastHeader && (
                            <HomeLink to="/">
                                <Logo
                                    src={
                                        fixedNav ||
                                        props.mobileHeaderTheme === 'light' ||
                                        scrolledPastHeader
                                            ? logoLightMobile
                                            : logoDarkMobile
                                    }
                                />
                            </HomeLink>
                        )}

                        {/* Mobile Logo - Not Home */}
                        {location.pathname !== '/' && (
                            <HomeLink to="/">
                                <Logo
                                    src={
                                        fixedNav ||
                                        props.mobileHeaderTheme === 'light' ||
                                        scrolledPastHeader
                                            ? logoLightMobile
                                            : logoDarkMobile
                                    }
                                />
                            </HomeLink>
                        )}

                        {/* Mobile BG Colour */}
                        <AnimatePresence>
                            {fixedNav && (
                                <BGColour
                                    {...toggleOpacity}
                                    style={{ backgroundColor: `${coolGrey}` }}
                                />
                            )}
                        </AnimatePresence>
                    </Fragment>
                )}
            </Container>
        </Wrapper>
    )
}

// Animation variants

const toggleOpacity = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
}

const togglePosition = {
    initial: {
        opacity: 1,
        y: 0,
        position: 'absolute',
    },
    variants: {
        visible: {
            opacity: 1,
            y: 0,
            position: 'fixed',
        },
        hidden: {
            opacity: 0,
            y: '-100%',
            transitionEnd: {
                position: 'absolute',
            },
        },
    },
    transition: {
        duration: 0.5,
    },
}

// Shared

const Heading = styled.div``
const MenuItem = styled(Link)``
const ExternalLink = styled.a`
    ${hoverState};
`
const HomeLink = styled(Link)`
    position: relative;
    z-index: 30;
    display: flex;
    ${hoverState};
`
const Logo = styled.img`
    width: 25px;
    height: 25px;
`

// Layout

const Wrapper = styled(motion.div)`
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: ${headerHeight}px;
`

const BGColour = styled(motion.div)`
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: ${headerHeight}px;
`

const Container = styled.div`
    ${container}
    ${padding}

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`

const Menu = styled.div`
    z-index: 30;
    position: relative;
    margin-top: -5px;

    ${MenuItem} {
        margin-right: 16px;
        ${navigation};
        color: ${white};
        transition: color 0.15s ease;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: #999999;
        }
    }
`

// Mobile

const MobileNavToggle = styled.div`
    position: relative;
    z-index: 50;
    padding: 10px;
    transform: translate(-10px);
`

const Hamburger = styled.img``
const Close = styled.img``
const MobileMenu = styled(motion.div)`
    z-index: 40;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: 120px 0 42px;
    ${padding};
    background-color: ${brown3};

    ${HomeLink} {
        position: fixed;
        top: 0;
        right: 30px;
        height: ${headerHeight}px;
        align-items: center;
    }

    ${MenuItem} {
        display: block;
        font-family: Basis Grotesque;
        font-size: 32px;
        line-height: 1.375;
        color: ${brown1};

        &:not(last-child) {
            margin-bottom: 20px;
        }
    }
`

const SocialNav = styled.div`
    margin-top: 100px;

    ${Heading}, ${ExternalLink} {
        font-size: 16px;
        line-height: 1.2;

        &:not(last-child) {
            margin-bottom: 8px;
        }
    }

    ${Heading} {
        font-family: Chronicle Display;
        font-weight: 300;
        font-style: italic;
    }

    ${ExternalLink} {
        font-family: Basis Grotesque;
        font-weight: 700;
        color: ${brown5};
    }
`

const query = graphql`
    query HeaderQuery {
        wordpressGlobal {
            acf_json
        }
    }
`

export default Header
