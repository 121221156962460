import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { body } from '../../../styles/type'
import { blue2, brown1 } from '../../../styles/colors'

const BlockText = (props) => {
	const { content } = props;
        
	return (
		<Text
			dangerouslySetInnerHTML={{__html: content}}  
		/>
	)
}


const Text = styled.div`
	${body};
    a {
        color: ${blue2};
        transition: color 0.15s ease;
        &:hover {
            color: ${brown1};
        }
    }
`

BlockText.wrapper = css`
    
`

export default BlockText
